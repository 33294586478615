.map,
.map-container {
  height: 80vh;
  width: 97vw;
  padding-right: 15px;
  padding-left: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

div.ol-custom-overviewmap {
  position: absolute;
  bottom: 25%;
  left: 0;
}

div.ol-custom-overviewmap:not(.ol-collapsed) div.ol-overviewmap-map {
  border: 1px solid black;
  width: 200px;
  height: 220px;
}

div.ol-custom-overviewmap div.ol-overviewmap-box {
  border: 2px solid red;
  overflow: hidden;
  display: block;
  cursor: pointer;
}

div.ol-custom-overviewmap:not(.ol-collapsed) button {
  right: 1px;
  bottom: 0;
}

@media only screen and (max-width: 768px) {
  div.ol-custom-overviewmap {
    position: absolute;
    bottom: 10%; /* Adjust the bottom position for mobile/tablet */
    left: 0;
  }

  div.ol-custom-overviewmap:not(.ol-collapsed) div.ol-overviewmap-map {
    border: 1px solid black;
    width: 150px;
    height: 170px;
  }

  div.ol-custom-overviewmap div.ol-overviewmap-box {
    border: 2px solid red;
    overflow: hidden;
    display: block;
    cursor: pointer;
  }

  div.ol-custom-overviewmap:not(.ol-collapsed) button {
    right: 1px;
    bottom: 0;
  }
}

/* Additional Media query for smaller screens like phones */
@media only screen and (max-width: 480px) {
  div.ol-custom-overviewmap:not(.ol-collapsed) div.ol-overviewmap-map {
    width: 120px;
    height: 130px;
  }
}

@media (max-width: 600px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
}
